<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-messages severity="info">
        <ng-template pTemplate>
          <div class="grid">
            <div class="col-8">
              <ng-container
                *ngIf="!status || status === AccountVerificationStatusEnum.NONE"
              >
                <h4>Your account is not verified!</h4>
                <div>
                  To join event as Shareholder or Proxy. Please upload your NRIC
                  or Passport for account verification.
                </div>
              </ng-container>
              <ng-container
                *ngIf="status === AccountVerificationStatusEnum.PENDING"
              >
                <h4>Your account is pending for verification</h4>
                <div>Your account will takes 24 hours to approve</div>
              </ng-container>
              <ng-container
                *ngIf="status === AccountVerificationStatusEnum.APPROVED"
              >
                <h4>Your account is verified</h4>
              </ng-container>
              <ng-container
                *ngIf="status === AccountVerificationStatusEnum.REJECTED"
              >
                <h4>Your account is verification is rejected</h4>
                <div>
                  <b>Due to the following reason:</b> {{ rejectMessage }}
                </div>
              </ng-container>
            </div>

            <div class="col-4">
              <div class="flex justify-content-end">
                <button
                  *ngIf="
                    !status || status === AccountVerificationStatusEnum.NONE
                  "
                  pButton
                  pRipple
                  icon="pi pi-check-circle"
                  label="Submit for Verification"
                  class="mr-2 mb-2"
                  (click)="submitForVerification()"
                ></button>
                <button
                  *ngIf="
                    status === AccountVerificationStatusEnum.PENDING ||
                    status === AccountVerificationStatusEnum.REJECTED
                  "
                  pButton
                  pRipple
                  icon="pi pi-check-circle"
                  label="Re-Submit for Verification"
                  class="mr-2 mb-2"
                  (click)="submitForVerification()"
                ></button>
              </div>
            </div>

            <div class="col-12">

              <div class="field grid">
                <label for="fullname" class="col-fixed w-18rem">Full Name</label>
                <div class="col">
                  <input
                  [disabled]="status === AccountVerificationStatusEnum.APPROVED"
                  type="fullname"
                  pInputText
                  placeholder="Fullname"
                  name="fullname"
                  [(ngModel)]="fullname"
                  (ngModelChange)="fullnameOnChange($event)"
                  #fullnameField="ngModel"
                  required
                  fullname
                  class="mr-2"
                />
                
                <!-- Optionally display an error message -->
                <div *ngIf="emailField.errors?.email && emailField.dirty">
                  Please enter a valid email.
                </div>
                </div>
              </div>

              <div class="field grid">
                <label for="email" class="col-fixed w-18rem">Email</label>
                <div class="col">
                  <input
                  [disabled]="status === AccountVerificationStatusEnum.APPROVED"
                  type="email"
                  pInputText
                  placeholder="Email"
                  name="email"
                  [(ngModel)]="email"
                  (ngModelChange)="emailOnChange($event)"
                  #emailField="ngModel"
                  required
                  email
                  class="mr-2"
                />
                
                <!-- Optionally display an error message -->
                <div *ngIf="emailField.errors?.email && emailField.dirty">
                  Please enter a valid email.
                </div>
                </div>
              </div>

              <div class="field grid">
                <label for="identity" class="col-fixed w-18rem"
                  >NRIC/Passport</label
                >
                <div class="col">
                  <input
                    [disabled]="
                      status === AccountVerificationStatusEnum.APPROVED
                    "
                    id="identity"
                    type="text"
                    pInputText
                    [(ngModel)]="identity"
                    (ngModelChange)="identityOnChange($event)"
                    class="mr-2"
                  />
                  <p-message
                    severity="info"
                    text="Only Alphanumeric"
                  ></p-message>
                </div>
              </div>

              <div class="field grid" *ngIf="!isNricVerified">
                <label class="col-fixed w-18rem">NRIC/Passport document</label>
                <div class="w-full">
                  <app-file-uploader
                    [file]="identityDocument"
                    [canEdit]="
                      status !== AccountVerificationStatusEnum.APPROVED
                    "
                    [enableDisplay]="true"
                    (fileUploaded)="addForm($event)"
                    (fileDeleted)="removeFormAttachment()"
                  ></app-file-uploader>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-messages>
    </div>
  </div>
</div>
