<input
  #addFile
  type="file"
  id="addFile"
  [hidden]="true"
  accept=".pdf, .jpeg, .jpg, .png"
  (change)="uploadFile($event)"
/>

<button
  *ngIf="canEdit"
  pButton
  pRipple
  icon="pi pi-upload"
  (click)="addFile.click()"
  class="p-button-primary mr-2 mb-2"
></button>
<button
  *ngIf="canEdit"
  [disabled]="!file"
  pButton
  pRipple
  icon="pi pi-trash"
  (click)="deleteFile()"
  class="p-button-danger mr-2 mb-2"
></button>

<div>{{ name }}</div>

<div *ngIf="enableDisplay" style="padding: 0px; background-color: #ced4da">
  <ng-container *ngIf="isPdfFormat(name)">
    <pdf-viewer
      [original-size]="false"
      [autoresize]="true"
      [fit-to-page]="true"
      [render-text]="true"
      [src]="file._id | storage | async"
      [zoom]="1"
      style="display: block; height: 75vh; width: 100%"
    ></pdf-viewer>
  </ng-container>
  <ng-container *ngIf="isImgFormat(name)">
    <img alt="gallery image" [src]="file._id | storage | async" />
  </ng-container>
  <ng-container *ngIf="!isImgFormat(name) && !isPdfFormat(name)">
    <label
      class="grid justify-content-center align-items-center"
      for="addPdf"
      style="width: 100%; height: 75vh; text-align: center"
    >
      <div>
        <i class="pi pi-upload" style="font-size: 2rem"></i>
        <h4>{{ description }}</h4>
      </div>
    </label>
  </ng-container>
</div>
