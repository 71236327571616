<head>
  <link href='https://fonts.googleapis.com/css?family=Bebas Neue' rel='stylesheet'>
</head>
<p-toast></p-toast>

<div class="flex min-h-screen">
    <!-- Cover Image -->
    <div class="cover-image" >
      <img src="assets/layout/images/user-login.png" alt="Background" style="width: 100%; height: 100%; position: sticky;top: 0;">
    </div>


    <div class="content-area" >

      <div   class="logo-container" >
        <img src="assets/layout/images/vpoll.png" alt="Logo 1" style="max-height: 50px; margin-right: 10px;">
                  
      </div>
      
      <h1 style="font-family: 'Bebas Neue'; color: rgb(100, 0, 146); 
      text-align: right; 
      padding-top: 5%;
      font-size: 60px;
      padding-right: 10%;">
        USER LOGIN</h1>
      <div class="w-full md:w-10 mx-auto" [formGroup]="form">
        <label for="email1" class="block text-900 text-xl font-medium mb-2"
          >Mobile No.</label
        >

        <div class="custom-inputgroup mb-3">
          <div>
            <p-dropdown
              [options]="countryMobileCode"
              [(ngModel)]="selectedCountryMobileCode"
              [ngModelOptions]="{ standalone: true }"
              optionLabel="name"
            >
              <ng-template pTemplate="selectedItem">
                <div
                  class="country-item country-item-value"
                  *ngIf="selectedCountryMobileCode"
                >
                  <img
                    src="assets/demo/flags/flag_placeholder.png"
                    [class]="
                      'flag flag-' +
                      selectedCountryMobileCode.code.toLowerCase()
                    "
                  />
                  <div>{{ selectedCountryMobileCode.name }}</div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="country-item">
                  <img
                    src="assets/demo/flags/flag_placeholder.png"
                    [class]="'flag flag-' + country.code.toLowerCase()"
                  />
                  <div>{{ country.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div style="flex-basis: 100%">
            <input
              type="text"
              pInputText
              formControlName="mobile"
              placeholder="121234567"
              class="w-full"
              style="padding: 1rem"
              required
            />
          </div>
        </div>

        <label for="password1" class="block text-900 font-medium text-xl mb-2"
          >Password</label
        >
        <p-password
          placeholder="Password"
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
          required
        ></p-password>

        <div class="flex align-items-center justify-content-between mb-5">
          <div class="flex align-items-center">
            <p-checkbox
              id="rememberme1"
              formControlName="rememberMe"
              [binary]="true"
              styleClass="mr-2"
            ></p-checkbox>
            <label for="rememberme1">Remember me</label>
          </div>
          <a
            class="font-medium no-underline ml-2 text-right cursor-pointer"
            style="color: var(--primary-color)"
            (click)="router.navigate(['forget-password'])"
            >Forgot password?</a
          >
        </div>
          <div style=" display: flex; justify-content: center; align-items: center; " >
            <img 
            src="assets/layout/images/button-04.gif" 
            alt="Sign In Button" 
            style="width: 50%; cursor: pointer;" 
            (click)="signin()"
          />
        </div>
        <div style=" display: flex; justify-content: center; align-items: center; " >
          <img 
          src="assets/layout/images/button-05.gif" 
          alt="Sign Up Button" 
          style="width: 50%; cursor: pointer;" 
          (click)="router.navigate(['sign-up'])"
        />
      </div>
      </div>

      <hr style="height:2px ;width: 80%;margin-left: 10%;border: none;background: rgb(221, 221, 221);"> 

      <div style="display: flex; justify-content: center; width: 100%; padding-top: 10px; padding-bottom: 20px">
          <mat-expansion-panel style="min-width: 76%; max-width: 76%; max-height: 1%; justify-content: center;border: 0px solid gray;" [expanded]="true"  >
            <mat-expansion-panel-header style="height:40px;background-color: #f4842c;" >
              <mat-panel-title >
                <!-- <div class="grid justify-content-center">
                  <div class="col-12 text-center"> -->
                    <!-- <h2 class="text-900 font-normal mb-2">Upcoming AGMs</h2> -->
                  <!-- </div>
                </div> -->
                Upcoming AGMs
              </mat-panel-title>
            </mat-expansion-panel-header>
        
          <div
            *ngIf="upcomingEvents.length"
            id="features"
            
            style="width: 100%; height:100%;" 
          >
        
            <p-carousel
              [value]="upcomingEvents"
              [numVisible]="1"
              [numScroll]="1"
              [circular]="true"
              [style]="{'width': '100%', 'height': '100%'}"
            >
              <ng-template let-event pTemplate="item" style="width: 100%;" >
                <div
                  class="border-round m-2 text-center p-2"
                  style="border: 0px solid var(--surface-d);width: 100%" 
                >
                  <div class="my-5 photo" style="width: 100%">
                    <img [alt]="event.name" [src]="event.logo" class="shadow-4" style="width: 25%" />
                  </div>
                  <div>
                    <h5 class="mb-1">{{ event.name }}</h5>
                    <h6 class="mt-0 mb-3">
                      {{ event.startAt | date: 'dd/MM/yy, hh:mm a' }} -
                      {{ event.endAt | date: 'hh:mm a' }}
                    </h6>
        
                    <div class="my-5">
                      <button
                        pButton
                        pRipple
                        class="mr-2 p-button-rounded"
                        (click)="goToLink(event.noticeOfAgmUrl)"
                      >
                        View Notice
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
        
          <div
          *ngIf="upcomingEvents.length === 0"
          id="features"
          class="py-4 px-2 lg:px-8 mx-0 lg:mx-8"

        >
          <div class="grid justify-content-center">
            <div class="col-12 text-center">
              <h2 class="text-900 font-normal mb-2">No Upcoming AGMs</h2>
            </div>
          </div>
        </div>
        </mat-expansion-panel>
      </div>


  </div>
</div>
