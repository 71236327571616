<div class="p-fluid" [formGroup]="form">
  <div class="field">
    <label htmlFor="name1">Company Name</label>
    <input pInputText id="name1" type="text" formControlName="name" />
  </div>
</div>

<div class="p-fluid" [formGroup]="form">
  <div class="field">
    <label htmlFor="email1">Company Email</label>
    <input pInputText id="email1" type="text" formControlName="email" />
    <div *ngIf="form.get('email').errors?.required && form.get('email').touched">
      Email is required.
    </div>
    <div *ngIf="form.get('email').errors?.email && form.get('email').touched">
      Invalid email format.
    </div>
  </div>
</div>

<button
  pButton
  pRipple
  label="Create"
  class="mr-2 mb-2"
  (click)="createCompany()"
  [disabled]="!form.valid"
></button>
