<div class="p-fluid p-formgrid grid" [formGroup]="inviteeForm">
  <div class="col-12">
    <label htmlFor="eventName">Name</label>
    <input pInputText id="eventName" type="text" formControlName="name" />
  </div>

  <div class="col-12">
    <label htmlFor="eventEmail">Email</label>
    <input pInputText id="eventEmail" type="text" formControlName="email" />
  </div>

  <div class="col-12">
    <label for="email1">Mobile No.</label>

    <div class="custom-inputgroup mb-3">
      <div>
        <p-dropdown
          [options]="countryMobileCode"
          [(ngModel)]="selectedCountryMobileCode"
          [ngModelOptions]="{ standalone: true }"
          optionLabel="name"
        >
          <ng-template pTemplate="selectedItem">
            <div
              class="country-item country-item-value"
              *ngIf="selectedCountryMobileCode"
            >
              <img
                src="assets/demo/flags/flag_placeholder.png"
                [class]="
                        'flag flag-' + selectedCountryMobileCode.code.toLowerCase()
                      "
              />
              <div>{{ selectedCountryMobileCode.name }}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="country-item">
              <img
                src="assets/demo/flags/flag_placeholder.png"
                [class]="'flag flag-' + country.code.toLowerCase()"
              />
              <div>{{ country.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div style="flex-basis: 100%">
        <input
          type="text"
          pInputText
          formControlName="mobile"
          placeholder="121234567"
          class="w-full"
          style="padding: 1rem"
        />
      </div>
    </div>
  </div>
</div>

<button
  *ngIf="operation === ModalOperationEnum.CREATE"
  pButton
  pRipple
  label="Create"
  class="mr-2 mb-2"
  (click)="createInvitee()"
></button>

<button
  *ngIf="operation === ModalOperationEnum.UPDATE"
  pButton
  pRipple
  label="Update"
  class="mr-2 mb-2"
  (click)="updateInvitee()"
></button>
