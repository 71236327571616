<div *ngIf="loaded" class="grid" style="margin-top: 0px">
  <div class="col-5">
    <app-file-uploader
      [file]="proxyFormFile"
      [canEdit]="true"
      (fileUploaded)="addProxyForm($event)"
      (fileDeleted)="removeProxyFormAttachment()"
    ></app-file-uploader>
  </div>

  <!-- <div class="col-1">
  </div> -->

  <div class="col-7">
    <p-tabView>
      <p-tabPanel header="Proxy Information">
        <div style="min-height: 70vh" [formGroup]="proxyForm">
          <div class="p-fluid p-formgrid grid">
            <div class="col-12 md:col-6">
              <label htmlFor="shareholder">Shareholder</label>
              <p-dropdown
                [disabled]="disableShareholderSelect"
                id="shareholder"
                [options]="shareholderOptions"
                formControlName="shareholderId"
                (onChange)="shareholderOnChange($event.value)"
                appendTo="body"
                [virtualScroll]="true"
              ></p-dropdown>

              <div
                class="shareholder-info"
                *ngIf="selectedShareholder"
                style="padding: 8px; margin: 12px; background: #eeeeee"
              >
                <ng-container *ngIf="shareUtilization">
                  <div class="pb-2"><b>Shareholder Information</b></div>
                  <div class="flex justify-content-between">
                    <div>Total number of Shares</div>
                    <div>
                      {{ shareUtilization.totalShares | number: "1.0-0" }}
                    </div>
                  </div>

                  <div class="flex justify-content-between">
                    <div>Allocated shares</div>
                    <div>{{ utilizedShares | number: "1.0-0" }}</div>
                  </div>

                  <div class="flex justify-content-between">
                    <div>Available Shares</div>
                    <div>{{ remainderShares | number: "1.0-0" }}</div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col-12 md:col-6">
              <label htmlFor="allocatedShares">Allocated Shares</label>
              <p-inputNumber
                id="allocatedShares"
                mode="decimal"
                [showButtons]="true"
                formControlName="allocatedShares"
                [min]="1"
              >
              </p-inputNumber>
              <small
                *ngIf="insufficientShares"
                id="allocatedShares-help"
                class="p-error"
                >Insufficient shares</small
              >
            </div>

            <div class="col-12 md:col-3">
              <label htmlFor="isChairmanAsProxy">Chairman as Proxy</label>
              <div>
                <p-inputSwitch
                  id="isChairmanAsProxy"
                  formControlName="isChairmanAsProxy"
                ></p-inputSwitch>
              </div>
            </div>

            <div class="col-12 md:col-9" *ngIf="isChairmanAsProxy.value">
              <label htmlFor="selectChairman">Select Chairman as Proxy</label>
              <p-dropdown
                id="selectChairman"
                [options]="chairmanOptions"
                [(ngModel)]="selectedChairmanId"
                (ngModelChange)="onChairmanSelect()"
                [ngModelOptions]="{standalone: true}"
                appendTo="body"
              ></p-dropdown>
            </div>

            <div class="col-12">
              <h5 style="margin: 0px">Proxy Information</h5>
            </div>
            <div class="col-12 md:col-4">
              <label htmlFor="identityNumber">NRIC/Passport</label>
              <input
                [readonly]="nameIDCdsDisableEdit"
                pInputText
                id="identityNumber"
                type="text"
                formControlName="identityNumber"
                (blur)="checkNricExisted($event)"
              />
            </div>
            <div class="col-12 md:col-4">
              <label htmlFor="cds">CDS</label>
              <input
                [readonly]="nameIDCdsDisableEdit"
                pInputText
                id="cds"
                type="text"
                formControlName="cds"
              />
            </div>
            <div class="col-12 md:col-4">
              <label htmlFor="mobile">Mobile No.</label>
              <input
                pInputText
                id="mobile"
                type="text"
                formControlName="mobile"
              />
            </div>

            <div class="col-12 md:col-6">
              <label htmlFor="shareholderName">Name</label>
              <input
                [readonly]="nameIDCdsDisableEdit"
                pInputText
                id="shareholderName"
                type="text"
                formControlName="name"
              />
            </div>

            <div class="col-12 md:col-6">
              <label htmlFor="email">Email</label>
              <input
                pInputText
                id="email"
                type="text"
                formControlName="email"
              />
            </div>

            <div class="col-12" formGroupName="voteSetting">
              <h5>Voting Settings</h5>
              <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-4">
                  <label htmlFor="isPreVote">Is Pre-Vote?</label>
                  <div>
                    <p-inputSwitch
                      id="isPreVote"
                      formControlName="isPreVote"
                    ></p-inputSwitch>
                  </div>
                </div>

                <div class="col-12 md:col-4">
                  <label htmlFor="chairmanVoteOnBehalf"
                    >Chairman as 2nd Proxy</label
                  >
                  <div>
                    <p-inputSwitch
                      id="chairmanVoteOnBehalf"
                      formControlName="chairmanVoteOnBehalf"
                    ></p-inputSwitch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <button
            *ngIf="operation === ModalOperationEnum.CREATE"
            pButton
            pRipple
            [disabled]="this.proxyForm.invalid"
            label="Create"
            class="p-button-raised p-button-primary p-button-rounded mr-2 mb-2"
            style="width: 30rem"
            (click)="createProxy()"
          ></button>

          <button
            *ngIf="operation === ModalOperationEnum.UPDATE"
            pButton
            pRipple
            [disabled]="this.proxyForm.invalid"
            label="Update"
            class="p-button-raised p-button-primary p-button-rounded mr-2 mb-2"
            style="width: 30rem"
            (click)="updateProxy()"
          ></button>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Proxy Voting">
        <div>
          <app-resolution-voting
            *ngIf="proxy; else noProxy"
            [operation]="proxy.isChairmanAsProxy ? VotingOperation.CHAIRMAN_VOTE_AS_PROXY: VotingOperation.PROXY_VOTE"
            [event]="event"
            [proxy]="proxy"
            [voting]="voting"
            (onVoteEvent)="onProxyPrevote($event)"
          ></app-resolution-voting>
          <ng-template #noProxy>
            <h4>Please create proxy 1st</h4>
          </ng-template>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
