<div class="p-fluid" [formGroup]="form">

  <!-- Name Field -->
  <div class="field">
    <label htmlFor="name">Name</label>
    <input pInputText id="name" type="text" formControlName="name" placeholder="John Doe" />
  </div>

  <!-- Email Field -->
  <div class="field">
    <label htmlFor="email">Email</label>
    <input pInputText id="email" type="email" formControlName="email" placeholder="example@email.com" />
  </div>

  <!-- Phone Number Field -->
  <div class="field">
    <label htmlFor="phoneNumber">Phone</label>
    <input pInputText id="phoneNumber" type="tel" formControlName="phoneNumber" placeholder="+601144578645" />
  </div>

  <!-- Company Name Field -->
  <div class="field">
    <label htmlFor="company">Company Name</label>
    <input pInputText id="company" type="text" formControlName="company" placeholder="Your Company, Inc." />
  </div>

  <!-- Subject Field -->
  <div class="field">
    <label htmlFor="subject">Subject</label>
    <input pInputText id="subject" type="text" formControlName="subject" placeholder="Enquiry about XYZ Product" />
  </div>

  <!-- Message Field -->
  <div class="field">
    <label htmlFor="message">Message</label>
    <textarea pInputTextarea id="message" rows="6" formControlName="message" placeholder="Your detailed message..."></textarea>
  </div>

  <!-- Submit Button -->
  <button
    pButton
    pRipple
    label="Submit"
    class="mr-2 mb-2"
    (click)="submitEnquiry()"
    [disabled]="!form.valid"
  >
  </button>
</div>
