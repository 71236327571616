<div class="p-fluid p-formgrid grid" [formGroup]="form">
  <div class="col-12">
    <label htmlFor="eventName">Event Name</label>
    <input pInputText id="eventName" type="text" formControlName="name" />
    <div *ngIf="form.get('name').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12">
    <label htmlFor="eventDescription">Description</label>
    <textarea
      pInputTextarea
      id="eventDescription"
      formControlName="description"
    ></textarea>
    <div *ngIf="form.get('description').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12 md:col-6">
    <label htmlFor="eventName">Event Date</label>
    <p-calendar
      formControlName="eventDate"
      [showTime]="false"
      [showSeconds]="false"
      [minDate]="today"
      inputId="time"
      appendTo="body"
    ></p-calendar>
    <div *ngIf="form.get('eventDate').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12 md:col-3">
    <label htmlFor="startTime">Start</label>
    <p-calendar
      formControlName="startTime"
      [timeOnly]="true"
      [hourFormat]="12"
      inputId="startTime"
      appendTo="body"
    ></p-calendar>
    <div *ngIf="form.get('startTime').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12 md:col-3">
    <label htmlFor="endtime">End</label>
    <p-calendar
      formControlName="endTime"
      [timeOnly]="true"
      [hourFormat]="12"
      inputId="endtime"
      appendTo="body"
    ></p-calendar>
    <div *ngIf="form.get('endTime').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12 md:col-6">
    <label htmlFor="noticeOfAgmUrl">Notice of AGM Link</label>
    <input
    
      pInputText
      id="noticeOfAgmUrl"
      type="text"
      formControlName="noticeOfAgmUrl"
    />
    <div *ngIf="form.get('noticeOfAgmUrl').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <div class="col-12 md:col-6">
    <label htmlFor="annualReportUrl">Annual Report Link</label>
    <input
      pInputText
      id="annualReportUrl"
      type="text"
      formControlName="annualReportUrl"
    />
    <div *ngIf="form.get('annualReportUrl').errors?.required && submitted" style="color: red;">
      This field is required.
    </div>
  </div>

  <!-- <div class="col-12" formGroupName="setting">
    <label htmlFor="eventName">Proxy Registration Cut Off Time</label>
    <p-calendar
      formControlName="proxyRegstrCutOffTime"
      [showTime]="true"
      [showSeconds]="true"
      inputId="time"
      appendTo="body"
    ></p-calendar>
  </div> -->

  <div class="col-12 mt-2">
    <h5 style="margin: 0px">Conference Live Stream Setup</h5>
  </div>

  <div class="col-12 md:col-6" formGroupName="setting">
    <label htmlFor="wowzaSdpUrl">Zoom Meeting Number</label>
    <input
      pInputText
      id="wowzaSdpUrl"
      type="text"
      formControlName="wowzaSdpUrl"
    />
  </div>
  <div class="col-12 md:col-6" formGroupName="setting">
    <label htmlFor="wowzaApplicationName">Zoom Passcode</label>
    <input
      pInputText
      id="wowzaApplicationName"
      type="text"
      formControlName="wowzaApplicationName"
    />
  </div>
  <div class="col-12 md:col-6" formGroupName="setting">
    <label htmlFor="wowzaStreamName">Zoom Invite Link</label>
    <input
      pInputText
      id="wowzaStreamName"
      type="text"
      formControlName="wowzaStreamName"
    />
  </div>
</div>

<button
  *ngIf="operation === ModalOperationEnum.CREATE"
  pButton
  pRipple
  label="Create"
  class="mr-2 mb-2"
  (click)="createEvent()"
></button>

<button
  *ngIf="operation === ModalOperationEnum.UPDATE"
  pButton
  pRipple
  label="Update"
  class="mr-2 mb-2"
  (click)="updateEvent()"
></button>
