<div class="grid">
  <div class="col-12">
    <label class="title">Chairman and Directors</label>
    <div>
      Chairman have the power to vote on behalf of shareholders if chairman is
      assigned as a proxy
    </div>
  </div>

  <div class="col-12">
    <p-table
      #dt1
      [value]="directorList"
      dataKey="id"
      [rows]="10"
      [loading]="false"
      [rowHover]="true"
      styleClass="p-datatable-gridlines"
      [paginator]="true"
      [globalFilterFields]="['name', 'cds', 'identityNumber']"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between flex-column sm:flex-row">
          <span class="p-input-icon-left mb-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              #filter
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search Keyword"
              class="w-full"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="flex justify-content-between align-items-center">
              Name
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              NRIC
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              CDS
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Number of Shares
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Role
            </div>
          </th>
          <th>
            <div class="flex justify-content-between align-items-center">
              Actions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-director>
        <tr>
          <td style="min-width: 12rem">{{ director.name }}</td>
          <td style="min-width: 12rem">{{ director.identityNumber }}</td>
          <td style="min-width: 12rem">{{ director.cds }}</td>
          <td style="min-width: 12rem">
            {{ director.numberOfShares | number: "1.0-0" }}
          </td>
          <td style="min-width: 12rem">
            {{ director.shareholderType | titlecase }}
          </td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No director or chairman found.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="7">Loading director list. Please wait.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
