export * from "./audit.interface";
export * from "./authentication.interface";
export * from "./common.interface";
export * from "./company.interface";
export * from "./context.interface";
export * from "./error.interface";
export * from "./event.interface";
export * from "./filesystem.interface";
export * from "./import.interface";
export * from "./invitee.interface";
export * from "./proxy.interface";
export * from "./resolution.interface";
export * from "./shareholder.interface";
export * from "./corporate.interface";
export * from "./user.interface";
export * from "./voting-report.interface";
export * from "./voting.interface";
export * from "./websocket.interface";
export * from "./enquiry.interface";
