<div class="p-fluid">
  <div class="field">
    <textarea
      pInputTextarea
      placeholder="Your Questions"
      [(ngModel)]="question"
      autoResize
      rows="4"
      cols="30"
      style="width: 100%; height: 100%"
    ></textarea>
  </div>
</div>

<button
  pButton
  pRipple
  class="p-button-outlined p-button-primary mr-2"
  label="Submit"
  (click)="askQuestion()"
></button>
