<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Companies</h5>

      <p-table
        #dt1
        [value]="companies"
        dataKey="id"
        [rows]="10"
        [loading]="loading"
        [rowHover]="true"
        styleClass="p-datatable-gridlines"
        [paginator]="true"
        [globalFilterFields]="['name', 'status']"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row">
            <button
              pButton
              pRipple
              label="Create"
              class="mr-2 mb-2"
              (click)="createCompany()"
            ></button>

            <span class="p-input-icon-left mb-2">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                #filter
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Search Keyword"
                class="w-full"
              />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div class="flex justify-content-between align-items-center">
                Name
              </div>
            </th>

            <th>
              <div class="flex justify-content-between align-items-center">
                Company Admin Email
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Created Date
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Status
              </div>
            </th>
            <th>
              <div class="flex justify-content-between align-items-center">
                Actions
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr>
            <td style="min-width: 12rem">
              {{ data.company.name }}
            </td>

            <td style="min-width: 14rem">
              {{ data?.systemUser.email }}
            </td>
            <td style="min-width: 10rem">
              {{ data.company.createdAt | date: "MM/dd/yyyy" }}
            </td>

            <td style="min-width: 12rem">
              <span [class]="'status-badge status-' + data.company.status">{{
                data.company.status
              }}</span>
            </td>
            <td style="min-width: 10rem">
              <button
                *ngIf="data.company.status === CompanyStatusEnum.ACTIVE"
                pButton
                pRipple
                label="Deactivate"
                (click)="deactivateCompany(data.company)"
                class="p-button-raised p-button-danger mr-2 mb-2"
              ></button>
              <button
                *ngIf="data.company.status === CompanyStatusEnum.INACTIVE"
                pButton
                pRipple
                label="Activate"
                (click)="activateCompany(data.company)"
                class="p-button-raised p-button-primary mr-2 mb-2"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No companies found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="7">Loading companies data. Please wait.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
